import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent, updateRequest } from "../common.js";
import Page from "./layout/Page.js";
import Dialog from "./layout/Dialog.js";
import BackButton from "./atoms/BackButton.js";
import user from "../assets/icons/user.svg";
import Header from "./layout/Header.js";
import Checkmark from "../assets/icons/checkmark-round.svg";
import BreakdownButton from "./atoms/BreakdownButton.js";
import { Spinner } from "./breakdown/ChatComponent.js";

export default function InviteMenu() {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const [email, setEmail] = useState("");
  const [inviteSent, setInviteSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      logEvent("invite", undefined, undefined, undefined, {
        invitee: email,
      });
      const request = await updateRequest("/api/login/invite", {
        user: email,
      });
      if (request.status === 202) {
        setInviteSent(true);
      } else {
        const jsonResponse = await request.json();
        if (jsonResponse.error) {
          setErrorMsg(jsonResponse.error);
        } else {
          setErrorMsg("Unknown error");
          console.error("Login request error:", { request, jsonResponse });
        }
      }
    } catch (err) {
      console.error("Email not sent error:", err);
      setErrorMsg(`Error sending invite: ${err}`);
    } finally {
      setIsLoading(false);
    }
  };

  const headerLeftContent = (
    <BackButton onClick={() => navigate("/user-menu")} />
  );
  const headerCenterContent = <h1>Invite Friend</h1>;

  return (
    <Page>
      <Header $left={headerLeftContent} $center={headerCenterContent} />
      <Dialog>
        {!inviteSent ? (
          <form onSubmit={handleSubmit}>
            <h3 className="icon">
              <img src={user} alt="user" />
            </h3>
            <h3 className="help">
              Enter friend&apos;s email to invite them to join
            </h3>
            <div className="input-row">
              <input
                name="user"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMsg(null);
                }}
              />
            </div>
            {errorMsg && <div className="error-message">{errorMsg}</div>}
            <div className="button-bar">
              <button
                className="pointer"
                type="button"
                onClick={() => navigate("/user-menu")}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button className="pointer" type="submit" disabled={isLoading}>
                {isLoading ? <Spinner size="16px" /> : "Send invite"}
              </button>
            </div>
          </form>
        ) : (
          <>
            <h3 className="icon">
              <img src={Checkmark} alt="checkmark" />
            </h3>
            <h3 className="help">
              Invitation link sent to <span className="value">{email}</span>
            </h3>
            <p>
              You have invited <span className="value">{email}</span> to
              LingoFlix. Invitation link expires in 24 hours.
            </p>
            <div className="button-bar">
              <BreakdownButton
                className="breakdown-button"
                onClick={() => navigate("/user-menu")}
                $primary
                style={{ width: "100%" }}
              >
                Back to Menu
              </BreakdownButton>
            </div>
          </>
        )}
      </Dialog>
    </Page>
  );
}
